document.addEventListener("DOMContentLoaded", function () {
  const main = new Main();
});

/* ======================================== *
/* Main
/* ======================================== */
class Main {
  observers: any;
  constructor() {
    this._init();
    this._scrollInit();
  }

  _init() {
    new GlobalMenu();
    new ScrollCheck();
    new SmoothScroll();
  }

  _inviewAnimation(el: any, inview: any) {
    if (inview) {
      el.classList.add("is-inview");
    } else {
      el.classList.remove("is-inview");
    }
  }

  _scrollInit() {
    this.observers = new ScrollObserver(
      ".js-scroll-obs",
      this._inviewAnimation.bind(this),
      {
        once: false,
      }
    );
  }
}

/* ======================================== *
/* Scroll Observer
/* ======================================== */
class ScrollObserver {
  cb: any;
  els: any;
  io: any;
  once: any;
  options: any;
  constructor(els: any, cb: any, options: any) {
    this.els = document.querySelectorAll(els);
    const defaultOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
      once: true,
    };
    this.cb = cb;
    this.options = Object.assign(defaultOptions, options);
    this.once = this.options.once;
    this._init();
  }
  _init() {
    const callback = function (this: any, entries: any, observer: any) {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          this.cb(entry.target, true);
          if (this.once) {
            observer.unobserve(entry.target);
          }
        } else {
          this.cb(entry.target, false);
        }
      });
    };
    this.io = new IntersectionObserver(callback.bind(this), this.options);
    this.io.POLL_INTERVAL = 100;

    this.els.forEach((el: any) => this.io.observe(el));
  }

  destroy() {
    this.io.disconnect();
  }
}

/* ======================================== *
/* GlobalMenu
/* ======================================== */
class GlobalMenu {
  DOM: any;
  eventType: any;
  constructor() {
    this.DOM = {};
    this.DOM.body = document.body;
    this.DOM.menuTrigger = document.querySelector('[data-trigger="menu"]');
    this.eventType = this._getEventType();
    this._addEvent();
  }
  _getEventType() {
    return window.ontouchstart ? "touchstart" : "click";
  }
  _toggleMenuStatus() {
    this.DOM.body.classList.toggle("is-display-menu");
  }
  _addEvent() {
    this.DOM.menuTrigger.addEventListener(
      this.eventType,
      this._toggleMenuStatus.bind(this)
    );
  }
}

/* ======================================== *
/* Smooth scroll
/* Thanks https://flex-box.net/js-smoothscroll/
/* ======================================== */
class SmoothScroll {
  DOM: any;
  eventType: any;
  smoothScrollTrigger: any;

  constructor() {
    this.DOM = {};
    this.DOM.body = document.body;
    this.smoothScrollTrigger =
      document.querySelectorAll<HTMLElement>('a[href^="#"]');

    this.eventType = this._getEventType();
    this._addScrollEvent();
  }

  _getEventType() {
    return window.ontouchstart ? "touchstart" : "click";
  }

  _addScrollEvent() {
    for (
      let ancIndex: number = 0;
      ancIndex < this.smoothScrollTrigger.length;
      ancIndex++
    ) {
      this.smoothScrollTrigger[ancIndex].addEventListener(
        this.eventType,
        (e: any) => {
          e.preventDefault();
          let href: any =
            this.smoothScrollTrigger[ancIndex].getAttribute("href");
          let targetElement: any = document.getElementById(
            href.replace("#", "")
          );
          const rect = targetElement.getBoundingClientRect().top;
          const offset = window.scrollY;
          const gap = 60;
          const target = rect + offset - gap;
          window.scrollTo({ top: target, behavior: "smooth" });
        }
      );
    }
  }
}

/* ======================================== *
/* Scroll check
/* ======================================== */
class ScrollCheck {
  constructor() {
    this._scrollCheck();
  }
  _scrollCheck() {
    const header = document.querySelector<HTMLElement>(".l-header");
    const headerHeight = header?.offsetHeight;
    window.addEventListener("scroll", function () {
      const supportPageOffset = window.scrollY !== undefined;
      const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
      const scrollVal = supportPageOffset
        ? window.scrollY
        : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop;

      if (typeof headerHeight === "number") {
        if (scrollVal >= headerHeight) {
          this.document.body.classList.add("is-scroll");
        } else {
          this.document.body.classList.remove("is-scroll");
        }
      }
    });
  }
}
